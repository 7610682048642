<template>
  <div>
  <ClientOnly>
  <Teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {{ dataStructure }}
    </component>
  </Teleport>
  </ClientOnly>
  <div
    :class="['container new-search-box-container wp-100 flex flex-column justify-center items-center absolute left-p-50 trans-x-50 overflow-visible',
    !streamDone ? 'disable-search-box' : '']"
    v-click-out-side="() => clickOutSide()"
  >
    <div class="flex flex-column wp-100 hp-100 relative overflow-visible maxw-595">
      <div :class="[isHome ? 'pb-20' : 'pb-12']" @click="focus(true)">
        <div
             class="
          new_search_box
          wp-100
          hp-100
          flex
          items-center
          bd-1 bd-gray-medium
          br-6
        "
        >
        <textarea class="bd-0 fw-400"
                  :class="[
            showUnfocusedText && !showBottomBox
              ? 'truncate truncate-1 text-unfocus overflow-hidden ellipsis'
              : 'overflow-auto ', showMobileSearch || !streamDone ? 'pointer-none disabled' : '']"
                  id="text-area-search"
                  @focusin="focus(true)"
                  v-model="searchQuery"
                  @keydown.enter="handleSearch"
                  @keydown="onKeyDown"
        ></textarea>
          <div
            class="
            absolute
            static-txt
            relative
            fs-16
            wp-100
            pointer-none
            flex
            gray-700
          "
            v-show="showPlaceHolder"
          >
            <!--          <ul
                        class="dynamic-txts overflow-hidden relative pr-3 ml-4 fs-16 h-18"
                      >
                        <li
                          class="h-18"
                          v-for="(item, index) in suggestionsDataTopKeywords"
                          :key="index"
                        >
                          <span>{{ item }}</span>
                        </li>
                      </ul>-->
            <ul class="overflow-hidden relative pr-3 ml-4 fs-16 h-18">
              <li class="h-18">
                <span class="typed-text" ref='typedTextContainer'>{{typedText}}</span>
                <!--<span class="typed-text" ref='typedText'></span><span class="cursor" ref='typedCursor'>&nbsp;</span>-->
              </li>
            </ul>
          </div>

        </div>
      </div>
      <button
        type="button"
        title="Search"
        aria-label="Search" :style="[isHome ? 'top: 12px; right: 12px' : 'top: 12px; right: 12px']"
        class="search-button absolute"
        :class="[isLoadingAIText || !streamDone ? 'pointer-none' : '']"
        @click.prevent="handleSearch"
      >
<!--        <svg v-if='streamDone' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="18" :fill="!showPlaceHolder ? '#C3FFD9' : '#2D3748'"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6152 22.5946C13.3134 22.5946 10.6367 19.918 10.6367 16.6162C10.6367 13.3143 13.3134 10.6377 16.6152 10.6377C19.917 10.6377 22.5936 13.3143 22.5936 16.6162C22.5936 17.9977 22.125 19.2698 21.3381 20.2822L25.364 24.3081L24.3071 25.365L20.2812 21.339C19.2688 22.126 17.9967 22.5946 16.6152 22.5946ZM21.099 16.6162C21.099 19.0925 19.0915 21.1 16.6152 21.1C14.1388 21.1 12.1313 19.0925 12.1313 16.6162C12.1313 14.1398 14.1388 12.1323 16.6152 12.1323C19.0915 12.1323 21.099 14.1398 21.099 16.6162Z"
                :fill="!showPlaceHolder ? '#257A66' : '#F7FAFC'"/>
        </svg>-->
        <svg v-if='streamDone' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="42" height="42" rx="21" :fill="!showPlaceHolder ? '#C3FFD9' : '#2D3748'"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.307 26.614C15.2715 26.614 12 23.3426 12 19.307C12 15.2715 15.2715 12 19.307 12C23.3426 12 26.614 15.2715 26.614 19.307C26.614 20.9956 26.0413 22.5504 25.0794 23.7877L30 28.7083L28.7083 30L23.7877 25.0794C22.5504 26.0413 20.9956 26.614 19.307 26.614ZM24.7873 19.307C24.7873 22.3337 22.3337 24.7873 19.307 24.7873C16.2803 24.7873 13.8268 22.3337 13.8268 19.307C13.8268 16.2803 16.2803 13.8268 19.307 13.8268C22.3337 13.8268 24.7873 16.2803 24.7873 19.307Z" :fill="!showPlaceHolder ? '#257A66' : '#F7FAFC'"/>
        </svg>
<!--        <svg v-else width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <rect width="36" height="36" rx="18" fill="#dcdcdc"/>-->
<!--          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6152 22.5946C13.3134 22.5946 10.6367 19.918 10.6367 16.6162C10.6367 13.3143 13.3134 10.6377 16.6152 10.6377C19.917 10.6377 22.5936 13.3143 22.5936 16.6162C22.5936 17.9977 22.125 19.2698 21.3381 20.2822L25.364 24.3081L24.3071 25.365L20.2812 21.339C19.2688 22.126 17.9967 22.5946 16.6152 22.5946ZM21.099 16.6162C21.099 19.0925 19.0915 21.1 16.6152 21.1C14.1388 21.1 12.1313 19.0925 12.1313 16.6162C12.1313 14.1398 14.1388 12.1323 16.6152 12.1323C19.0915 12.1323 21.099 14.1398 21.099 16.6162Z"-->
<!--                fill="#fff"/>-->
<!--        </svg>-->

        <svg v-else width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="42" height="42" rx="21" fill="#dcdcdc"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.307 26.614C15.2715 26.614 12 23.3426 12 19.307C12 15.2715 15.2715 12 19.307 12C23.3426 12 26.614 15.2715 26.614 19.307C26.614 20.9956 26.0413 22.5504 25.0794 23.7877L30 28.7083L28.7083 30L23.7877 25.0794C22.5504 26.0413 20.9956 26.614 19.307 26.614ZM24.7873 19.307C24.7873 22.3337 22.3337 24.7873 19.307 24.7873C16.2803 24.7873 13.8268 22.3337 13.8268 19.307C13.8268 16.2803 16.2803 13.8268 19.307 13.8268C22.3337 13.8268 24.7873 16.2803 24.7873 19.307Z" fill="white"/>
        </svg>

      </button>
      <button v-if='showDeleteButton' type="button" title="Delete" aria-label="Delete"
              :style="[isHome ? 'top: 21px; right: 60px; width: 24px;' : 'top: 21px; right: 60px; width: 24px;']" class="absolute h-24"
              @click.prevent="deleteSearchText()">
        <img :src="`${ASSETS_CDN}/icons/search/close.svg`" alt='Delete'/>
      </button>
    </div>
    <template v-if="!isPhone && focused">
      <SuggestedBox v-if="!showPlaceHolder && !searchQuery" />
    </template>
    <SearchMobile
      v-else-if="isPhone"
      v-model:search-query="searchQuery"
      :show="showMobileSearch"
      @closeSearch="onCloseMobileSearch"
      @handleSearch="handleSearch"
      @trendingClick="onOptionData"
      @deleteSearchText="deleteSearchText"
    />
    <template v-if="isPhone">
      <Teleport :disabled="!showMobileSearch" to="#search-bar-mobile">
        <NewAutocompleteItem
          :mobile="showMobileSearch"
          v-if="
            searchQuery &&
            ((isPhone && showMobileSearch) || !isPhone) &&
            focused
          "
          :open-options="!showPlaceHolder"
          :search-terms="searchQuery"
          ref="autocompleteItem"
          @update:optionData="onOptionData"
          newAutoCompleteItem
          :count-words="countWords"
          :showBottomBox="showBottomBox || focused"
        />
      </Teleport>
    </template>
    <template v-else>
      <NewAutocompleteItem
        :mobile="showMobileSearch"
        v-if="
          searchQuery && ((isPhone && showMobileSearch) || !isPhone) && focused
        "
        :openOptions="!showPlaceHolder"
        :search-terms="searchQuery"
        ref="autocompleteItem"
        @update:optionData="onOptionData"
        newAutoCompleteItem
        :count-words="countWords"
        :showBottomBox="showBottomBox || focused"
        @closeAutoComplete='clickOutSide()'
      />
    </template>
  </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import SuggestedBox from './SuggestedBoxNew'
import NewAutocompleteItem from './NewAutoCompleteItem'
import SearchMobile from './SearchMobile'
import { clickOutSide as vClickOutSide } from '~/directives/newClickOutSide'
import { PHONE } from '~/constant/common'
import { useRoute, useRouter } from 'vue-router'
import {assetCDN, debounce} from '~/helper'
import { useSearchStore } from '~/store/pinia/search'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'

defineProps({
  showBottomBox: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits(['trigger'/*, 'update:modelValue'*/])

const focused = ref(false)
const multiline = ref(false)
const searchQuery = ref('')
const searchStore = useSearchStore()
const route = useRoute()
const router = useRouter()
const autocompleteItem = ref(null)
const showMobileSearch = ref(false)
const deleteStatus = ref(false)
const typedText = ref('')
const typedTextContainer = ref(null)
const ASSETS_CDN = assetCDN()
const generalStore = useGeneralInfoStore()

/*const textArray = [
  '10 day unique culture of Japan',
  '10 day hidden wonders of Vietnam',
  'Thailand island hopping',
  'Family tour to India',
  '8 day imperial cities of Morocco',
  'A week in Italy'
]*/

const textArray = computed(() => {
  const list = generalStore.carousel.listItems
  return list.map(item => item.placeholder)
})

const typingDelay = 60;
const erasingDelay = 50;
const newTextDelay = 500; // Delay between current and next text

let textIndex = 0
let charIndex = 0
let timeoutId = null

const type = () => {
  if(showPlaceHolder.value && timeoutId) {
    // console.log(timeoutId, ' [typing] textIndex ',textIndex)
    if((charIndex === 0) && textArray.value[textIndex]?.length) {
      searchStore.setPlaceholderTextIndex(textIndex)
    }
    if (charIndex < textArray.value[textIndex]?.length) {
      // console.log(' [typing] commit ',textIndex)
      // store.commit('searchStore/setPlaceholderTextIndex', textIndex)
      typedText.value += textArray.value[textIndex].charAt(charIndex)
      charIndex++
      setTimeout(type, typingDelay)
    }
    else {
      setTimeout(erase, newTextDelay);
    }
  } else {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

const erase = () => {
  if (charIndex > 0) {
    typedText.value = textArray.value[textIndex].substring(0, charIndex-1)
    charIndex--
    setTimeout(erase, erasingDelay)
  }
  else {
    textIndex++
    if(textIndex >= textArray.value?.length) {
      textIndex = 0
    }
    setTimeout(type, typingDelay + 500)
  }
}

const placeholderTyping = () => {
  clearTimeout(timeoutId)
  timeoutId = null

  if(textArray.value?.length) timeoutId = setTimeout(type, newTextDelay)
  // console.log(text,' [typing] placeholderTyping ', timeoutId)
}

const focus = (value) => {
  if(streamDone.value) {
    const element = document.getElementById('text-area-search')
    if (!value) {
      if (element) element.scrollTop = 0
    } else {
      if (element) element.focus()
      setHeightTextArea()
    }
    if (isPhone.value && value) {
      showMobileSearch.value = true
    }
    focused.value = value
    emits('trigger',value)
  } else {
    focused.value = false
    emits('trigger',false)
  }
}

const showUnfocusedText = computed(() => {
  // console.log('[search] 2',focused.value)
  return !focused.value && !!searchQuery.value
})

const isLoadingAIText = computed(
  () => searchStore.getGenerativeTextStatus,
)

const windowWidth = computed(
  () => generalStore.generalData.windowWidth,
)

const isPhone = computed(() => windowWidth.value <= PHONE)

const showPlaceHolder = computed(() => {
  return !searchQuery.value && !focused.value
})

const countWords = computed(() => {
  if(searchQuery.value) return searchQuery.value?.split(' ').filter((word) => word !== '')?.length
  return searchQuery.value
})

const onKeyDown = (e) => {
  if (countWords.value === 60 && e.keyCode === 32) {
    e.preventDefault()
  }
  if (e.keyCode === 13) {
    e.preventDefault()
    emits('trigger',false)
  }
}

const onOptionData = (value) => {
  {
    searchQuery.value = value
  }
}

const onCloseMobileSearch = (query) => {
  showMobileSearch.value = false
  searchQuery.value = query
  focus(false)
}

const clickOutSide = () => {
  if (!isPhone.value) {
    focus(false)
  }
}

const handleSearch = () => {
  // document.body.classList.remove('search-popup-open')

  if (!searchQuery.value) {
    const element = document.getElementById('text-area-search')
    element.focus()
  } else if (!isLoadingAIText.value) {
    focused.value = false
    deleteStatus.value = true
    router.push({
      name: 'search-result',
      query: {
        q: searchQuery.value,
      },
    })
  }
}

const debounceCallSuggestionSearch = debounce(() => {
  if (searchQuery.value) {
    searchStore.setSuggestionByKeyword(searchQuery.value)
  }
}, 500)

const isHome = computed(() => {
  return route.name === 'home'
})

const setHeightTextArea = () => {
  const element = document.getElementById('text-area-search')
  if (element) {
    element.style.height = '24px'
    element.style.height = element.scrollHeight + 'px'
    multiline.value = element.scrollHeight > 24
    if (countWords.value > 60) {
      searchQuery.value = searchQuery.value.split(' ').slice(0, 60).join(' ')
    }
  }
}

const nextSlideClick = computed(() => generalStore.carousel.nextSlide)
const preSlideClick = computed(() => generalStore.carousel.preSlide)

watch(nextSlideClick, (nextTo) => {
  clearTimeout(timeoutId)
  timeoutId = null

  typedText.value = ''
  textIndex = nextTo
  charIndex = 0

  placeholderTyping()
})

watch(preSlideClick, (preTo) => {
  clearTimeout(timeoutId)
  timeoutId = null

  typedText.value = ''
  textIndex = preTo
  charIndex = 0

  placeholderTyping()
})

watch(searchQuery, (value) => {
  setHeightTextArea()
  if (value?.length) {
    debounceCallSuggestionSearch()
  }
})

watch(
  () => route.query.q,
  (value) => {
    if (value) {
      searchQuery.value = value
      if (isPhone.value) {
        onCloseMobileSearch(searchQuery.value)
      }
    }
  },
)

watch(showPlaceHolder, (value) => {
  if(value) {
    typedText.value = ''
    textIndex++
    charIndex = 0
    placeholderTyping()
  } else {
    clearTimeout(timeoutId)
    timeoutId = null
  }
})

// detect Input Method Editor (IME)
const addIMEListener = () => {
  const textArea = document.getElementById('text-area-search')
  textArea.addEventListener('compositionupdate', onCompositionUpdate, false);
}

const removeIMEListener = () => {
  const textArea = document.getElementById('text-area-search')
  textArea.removeEventListener('compositionupdate', onCompositionUpdate, false);
}

const onCompositionUpdate = (e) => {
  if (e.data === e.target.value) {
    // Data of IME is only right if it is still using IME so we need to extract from the last character from data or it will be wrong
    searchQuery.value = e.target.value + e.data.charAt(e.data.length - 1)
  }
}

const streamDone = computed(() => searchStore.streamDone)

const showDeleteButton = computed(() => {
  if(isPhone.value) return false
  return streamDone.value && searchQuery.value
})

const deleteSearchText = () => {
  searchQuery.value = ''
}

const dataStructure = computed(() => {
  return {
    "@type": "WebSite",
    "@id": "https://www.designerjourneys.com/#website",
    "url": "https://www.designerjourneys.com/search-result",
    "name": `Search Results for ${searchQuery.value} | Designer Journeys`,
    "description": `Best trip plans, local designers, travel guide, and travel inspirations for your search ${searchQuery.value}.`,
    "potentialAction": [
      {
        "@type": "AskAction",
        "name": "Design your trip",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": `${useRuntimeConfig().public.publicDomain}/design`,
          "inLanguage": "en-US",
          "actionPlatform": [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform"
          ]
        }
      },
      {
        "@type": "SearchAction",
        "target": `https://www.designerjourneys.com/search-result?q=${searchQuery.value}`,
        "query-input": `required name=${searchQuery.value}`
      }
    ],
    "inLanguage": "en-US"
  }

})

onMounted(async () => {
  // console.log('[typing] onMounted', textIndex)
  addIMEListener()
  if (route.name === 'search-result') {
    searchQuery.value = route?.query?.q
  }
  await nextTick()
  placeholderTyping()
})

onBeforeUnmount(() => {
  typedText.value = ''
  textIndex = 0
  charIndex = 0

  clearTimeout(timeoutId)
  timeoutId = null

  searchStore.setPlaceholderTextIndex(textIndex)
  removeIMEListener();
})
</script>

<style lang="scss" scoped>
.new-search-box-container {
  top: -180px;
  max-width: 635px;

  @include desktop {
    top: -160px;
  }

  @include phone {
    top: -100px;
  }


    :deep(.custom-option-item) {
      padding: 8px 10px;
      line-height: 24px;
      &:hover {
        background-color: #f0f0f0;
        border-radius: 12px;
      }
    }


  :deep(opened) {
    top: 75px;
    border: none;
    padding-left: 10px;
    margin-top: 5px;
    &::-webkit-scrollbar-thumb {
      background-color: $gray-medium;
    }

    li {
      padding: 8px 10px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
  }

  &.disable-search-box {
    textarea {color: #9c9c9c}
    .new_search_box, textarea, .dynamic-txts li::after {background-color: #F5F5F5;}
  }
}
.new_search_box {
  max-width: 595px;
  background: #fff;
  height: fit-content;
  min-height: 66px;
  max-height: 180px;
  padding: 12px 12px 12px 16px;
}
textarea {
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  resize: none;
  width: calc(100% - 50px);
  margin-right: 10px;
  height: 24px;
  padding-right: 10px;
  max-height: 124px;
  break-before: avoid;
  word-wrap: break-word;
}

.text-unfocus {
  overflow: hidden;
  height: 24px !important;
}

.search-button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.dynamic-txts li {
  position: relative;
  list-style: none;
  top: 0;
  animation: slide 15s steps(3) infinite;
  width: fit-content;
}

.dynamic-txts li::after {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  border-left: 1px solid #000;
  animation: typing 5s steps(10) infinite, blink-caret 0.75s step-end infinite;
}
@keyframes typing {
  20%,
  40%,
  80% {
    left: calc(100%);
  }
  100% {
    left: 0;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}

@keyframes slide {
  100% {
    top: -300%;
  }
}
</style>
