<template>
  <div
    class="
      br-1000
      bg-gray-100
      w-fit-content
      fs-16
      lh-24
      hand
      z-2
      bd-1 bd-gray-300-new
    "
    v-if="!loading"
  >
    <router-link class='flex items-center pl-8 pr-12 pt-4 pb-6'
      :to="{
        name: 'search-result',
        query: {
          q: props.text,
          type: 'keyword',
        },
      }"
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87134 9.74268C2.18097 9.74268 0 7.56171 0 4.87134C0 2.18097 2.18097 0 4.87134 0C7.56171 0 9.74268 2.18097 9.74268 4.87134C9.74268 5.99705 9.36084 7.03358 8.71961 7.85847L12 11.1389L11.1389 12L7.85847 8.71961C7.03358 9.36084 5.99705 9.74268 4.87134 9.74268ZM8.52484 4.87134C8.52484 6.88911 6.88911 8.52484 4.87134 8.52484C2.85356 8.52484 1.21783 6.88911 1.21783 4.87134C1.21783 2.85356 2.85356 1.21783 4.87134 1.21783C6.88911 1.21783 8.52484 2.85356 8.52484 4.87134Z" fill="#718096"/>
      </svg>
      <span class="black ml-4">
        {{ props.text }}
      </span>
    </router-link>
  </div>
  <template v-else>
    <SkeletonLoading
      :height="'26px'"
      :width="randomWidth"
      :border-radius="'1000px'"
    />
  </template>
</template>

<script setup>
import SkeletonLoading from '~/components/globals/Loading/SkeletonLoading'
import { getRandomArbitrary } from '~/helper'
import { computed  } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const randomWidth = computed(() => `${getRandomArbitrary(50, 175)}px`)
</script>

<style lang="scss" scoped></style>
