import { defineStore } from 'pinia'
import localDesignerApi from '~/composables/api/localDesignerApi'
import searchApi from '~/composables/api/searchApi'
import { SEARCH_TAB } from '~/constant/common'

export const useSearchStore = defineStore('storeSearch', {
  state: () => ({
    suggestionsSearch: {
      keyword: [],
      activities: [],
      popular_trips: [],
      isLoading: false,
      list: {},
    },
    suggestionKeyword: {
      activities: [],
      destinations: [],
      keywords: [],
      isLoading: false,
    },
    searchResult: {} as any,
    infiniteScroll: {
      tripPlans: [] as any[],
      page: 1,
      isLoading: false,
      totalPage: 1,
      limit: 12,
      totalTrip: 0,
    },
    generativeText: {
      data: '',
      isLoading: false,
    },
    searchLocalDesigners: {
      data: [],
      reviews: [],
      isLoading: false,
    },
    apiLoaded: true,
    tabSelected: SEARCH_TAB[0].id,
    dataTravelGuidesBlog: {},
    featureTrip: null,
    streamDone: true,
    tabVisible: SEARCH_TAB,
    showNoResult: false,
    placeholderTextIndex: 0,
    recaptcha: null,
    tripPlans: null as any,
    reviews: null as any,
    localDesigners: null as any,
    blogs: null as any,
  }),
  getters: {
    // NOT USE getLDById: (state) => (id: string) => state?.localDesigners?.find((ld: any) => ld.id === id),
    // NOT USE getLDs: (state) => state?.localDesigners,
    // NOT USE getSearchResultPrimaryTrip: (state) => state?.featureTrip || null,
    getSuggestionSearchStatus: (state) => state?.suggestionsSearch?.isLoading,
    getSuggestionKeyword: (state) => {
      const newSuggestionByKeyword = {
        activities: state?.suggestionKeyword?.activities || [],
        destinations: state?.suggestionKeyword?.destinations || [],
        keywords: state?.suggestionKeyword?.keywords || [],
      }
      return newSuggestionByKeyword
    },
    getSuggestionKeywordStatus: (state) => state?.suggestionKeyword?.isLoading,
    // NOT USE getSearchResult: (state) => state?.searchResult,
    // NOT USE getGenerativeText: (state) => state?.generativeText?.data,
    getGenerativeTextStatus: (state) => state?.generativeText?.isLoading,
    getInfiniteLoadingTripPlans: (state) => state.infiniteScroll.tripPlans,
    getInfiniteLoadingStatus: (state) => state?.infiniteScroll?.isLoading,
    shouldLoadMore: (state) => !(state?.infiniteScroll?.tripPlans?.length === state.searchResult?.total),
    getSearchLocalDesigners: (state) => state?.searchLocalDesigners?.data,
    getSearchLocalDesignersReviews: (state) => state?.searchLocalDesigners?.reviews,
    // NOT USE getSearchLocalDesignersStatus: (state) => state?.searchLocalDesigners?.isLoading,
  },
  actions: {
    async getSuggestionsSearch() {
      this.setSuggestionsSearchStatus(true)
      const res = (await searchApi.getSuggestionSearch()).data.value
      this.setSuggestionsSearch(res?.data)
    },
    async setSuggestionByKeyword(keyword: string) {
      this.setSuggestionKeywordStatus(true)
      const params = {
        q: keyword,
        limit: 10,
      }
      const { data } = await searchApi.getSuggestionByKeyword(params)
      this.setSuggestionKeyword(data.data)
    },

    async getSearchResult(paramsRoot: { keyword: string; type: string; default: string; currency: string }) {
      if (this.infiniteScroll.isLoading) return

      if (this.infiniteScroll.page > this.infiniteScroll.totalPage) {
        this.setInfiniteLoadingStatus(false)
        this.setAPILoaded(true)
        return
      }

      this.setInfiniteLoadingStatus(true)
      this.setAPILoaded(false)

      const params = {
        q: paramsRoot?.keyword || undefined,
        type: paramsRoot?.type || undefined,
        page: this.infiniteScroll.page,
        limit: this.infiniteScroll.limit,
        // get_feature_trip: 1
        default: paramsRoot?.default || undefined,
        currency: paramsRoot?.currency || undefined,
      }

      const { data } = await searchApi.getSearchResult(params)

      this.setAPILoaded(true)
      this.setSearchResult(data?.data)
      this.setInfiniteLoadingTripPlans(data?.data)
    },

    async getLocalDesigners() {
      this.setLocalDesignersStatus(true)
      const params = {
        designers_id: this.searchResult?.designers_id.join(','),
        limit: -1,
        destinations: this.searchResult?.destinations.toString() || undefined,
      }
      const { data } = await searchApi.getDesignersSearch(params)
      this.setLocalDesigners(data.data)
    },

    async getLDReviews() {
      const params = {
        country_ids: this.searchResult?.country_codes.join(','),
        limit: 12,
      }
      const res = (await localDesignerApi.getTravellerReviews(params)).data.value
      this.setLocalDesignerReviews(res)
    },

    async getTravelGuideTabData() {
      const params = {
        country_codes: this?.searchResult?.country_codes.toString() || undefined,
        destinations: this?.searchResult?.destinations.toString() || undefined,
        activities: this?.searchResult?.activities.toString() || undefined,
      }

      const resp = await searchApi.getTravelGuidesAndBlogs(params)
      this.setDataTravelGuidesBlog(resp?.data?.data)
      if (this.searchResult?.trips) this.setShowNoResult(false)
    },

    // Mutations
    // NO USE setTripPlansResult(payload: any) {
    //   this.tripPlans = payload
    // },
    // NO USE setReviewsResult(payload: any) {
    //   this.reviews = payload
    // },
    // NO USE setLDsResult(payload: any) {
    //   this.localDesigners = payload
    // },
    // NO USE setBlogsResult(payload: any) {
    //   this.blogs = payload
    // },
    setSuggestionsSearchStatus(payload: boolean) {
      this.suggestionsSearch.isLoading = payload
    },
    setSuggestionsSearch(payload: any) {
      this.suggestionsSearch.list = payload
      this.suggestionsSearch.isLoading = false
    },
    setSuggestionKeywordStatus(payload: boolean) {
      this.suggestionKeyword.isLoading = payload
    },
    setSuggestionKeyword(payload: any) {
      this.suggestionKeyword.activities = payload?.activities
      this.suggestionKeyword.destinations = payload?.destinations
      this.suggestionKeyword.keywords = payload?.keywords
      this.suggestionKeyword.isLoading = false
    },
    setSearchResult(payload: any) {
      this.searchResult = payload
    },
    setGenerativeTextStatus(payload: boolean) {
      this.generativeText.isLoading = payload
    },
    setGenerativeText(payload: any) {
      this.generativeText.data = payload
      this.generativeText.isLoading = false
    },
    setStreamGenerativeText(payload: string) {
      this.generativeText.data += payload
    },
    setInfiniteLoadingTripPlans(payload: any) {
      if (this.infiniteScroll.page === 1) this.infiniteScroll.totalTrip = payload?.total
      this.infiniteScroll.isLoading = false
      this.infiniteScroll.page++
      this.infiniteScroll.totalPage = Math.ceil(payload?.total / this.infiniteScroll.limit) || 1
      this.infiniteScroll.tripPlans = payload?.trips ? [...this.infiniteScroll.tripPlans, ...payload?.trips] : [...this.infiniteScroll.tripPlans]
    },
    setInfiniteLoadingStatus(payload: boolean) {
      if (payload) {
        this.infiniteScroll.isLoading = payload
      }
    },
    setLocalDesignersStatus(payload: boolean) {
      this.searchLocalDesigners.isLoading = payload
    },
    setLocalDesigners(payload: any) {
      this.searchLocalDesigners.data = payload
      this.searchLocalDesigners.isLoading = false
    },
    setLocalDesignerReviews(payload: any) {
      this.searchLocalDesigners.reviews = payload
    },
    resetData() {
      const newState = {
        suggestionsSearch: {
          keyword: [],
          activities: [],
          popular_trips: [],
          isLoading: false,
          list: {},
        },
        suggestionKeyword: {
          activities: [],
          destinations: [],
          keywords: [],
          isLoading: false,
        },
        searchResult: {},
        infiniteScroll: {
          tripPlans: [],
          page: 1,
          isLoading: false,
          totalPage: 1,
          limit: 12,
          totalTrip: 0,
        },
        generativeText: {
          data: '',
          isLoading: this.generativeText.isLoading,
        },
        apiLoaded: true,
        tabSelected: SEARCH_TAB[0].id,
        featureTrip: null as any,
      }
      this.searchResult = newState.searchResult
      this.infiniteScroll = newState.infiniteScroll
      this.generativeText = newState.generativeText
      this.tabSelected = newState.tabSelected
      this.featureTrip = newState.featureTrip
      this.dataTravelGuidesBlog = {}
    },
    setAPILoaded(payload: boolean) {
      this.apiLoaded = payload
    },
    setTabActive(payload: number) {
      this.tabSelected = payload
    },
    setDataTravelGuidesBlog(payload: any) {
      this.dataTravelGuidesBlog = payload
    },
    setFeatureTrip(payload: any) {
      this.featureTrip = payload
    },
    setStreamDone(payload: boolean) {
      this.streamDone = payload
    },
    setTabVisible(payload: any) {
      this.tabVisible = payload
    },
    setShowNoResult(payload: boolean) {
      this.showNoResult = payload
    },
    setPlaceholderTextIndex(payload: number) {
      this.placeholderTextIndex = payload
    },
    setRecaptcha(payload: any) {
      this.recaptcha = payload
    },
  },
})
