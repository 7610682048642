<template>
  <div id='suggested-box'
    class="relative suggested-box br-6 wp-100 bg-white hp-100 flex pa-12 bd-1 bd-gray-medium br-6 mb-bd-n mb-db overflow-auto"
    :class="[props.mobile ? 'flex-column grid-gap-24 px-20' : '']">
    <div :class="[props.mobile ? 'trending-mobile' : '']">
      <SkeletonSlot :loading="isLoading">
        <template #main>
          <div v-for='(title, order) in categoriesTitle' :key='order'
          class='flex flex-wrap grid-gap-8 grid-column-gap-4 mb-12'>
            <h3 class="gray-900 fs-16 lh-24 fw-700 ttc wp-100">{{ splitTitle(title) }}</h3>
            <TrendingSuggested
              v-for="(item, index) in suggestionsData[title]"
              :key="index"
              :text="item"
            />
          </div>
        </template>
        <template #skeleton>
          <TrendingSuggested :loading="isLoading" />
        </template>
      </SkeletonSlot>
<!--      <div
        class="
          mt-8
          flex flex-wrap
          grid-gap-8 grid-column-gap-4
          overflow-hidden
        "
      >
        <SkeletonSlot :loading="isLoading">
          <template #main>
            <TrendingSuggested
              v-for="(item, index) in suggestionsData[title]"
              :key="index"
              :text="item"
            />
          </template>
          <template #skeleton>
            <TrendingSuggested :loading="isLoading" />
          </template>
        </SkeletonSlot>
      </div>-->
<!--      <h3
        class="gray-900 fs-16 lh-24 fw-700"
        :class="[props.mobile ? 'mt-24' : 'mt-16']"
      >
        Suggested For You
      </h3>
      <div class="mt-8 flex flex-wrap grid-gap-4">
        <SkeletonSlot :loading="isLoading">
          <template #main>
            <ActivitySuggested
              v-for="(item, index) in suggestionsData?.activities"
              :key="index"
              :text="item"
            />
          </template>
          <template #skeleton>
            <ActivitySuggested :loading="isLoading" />
          </template>
        </SkeletonSlot>
      </div>-->
    </div>
<!--    <div-->
<!--      class="trip-plan"-->
<!--      :class="[props.mobile ? 'trip-plan-mobile' : ' pa-12']"-->
<!--    >-->
<!--      <h3 class="gray-900 fs-16 lh-24 fw-700 mb-8">Extraordinary Trip Plans</h3>-->
<!--      <div-->
<!--        class="flex"-->
<!--        :class="[-->
<!--          props.mobile ? ' grid-gap-8 flex-wrap' : 'flex-column grid-gap-12',-->
<!--        ]"-->
<!--      >-->
<!--        <SkeletonSlot :loading="isLoading" :itemNumber="2">-->
<!--          <template #main>-->
<!--            <SuggestedTripCard-->
<!--              v-for="item in suggestionsData?.popular_trips"-->
<!--              :key="item.id"-->
<!--              :data="item"-->
<!--            />-->
<!--          </template>-->
<!--          <template #skeleton>-->
<!--            <SuggestedTripCard :loading="isLoading" />-->
<!--          </template>-->
<!--        </SkeletonSlot>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import TrendingSuggested from './TrendingSuggested'
// import ActivitySuggested from './ActivitySuggested'
// import SuggestedTripCard from './SuggestedTripCard'
import SkeletonSlot from '~/components/globals/Loading/SkeletonSlot'
import { computed } from 'vue'
import { useSearchStore } from '~/store/pinia/search'

const props = defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['trending-click'])
const searchStore = useSearchStore()

const categoriesTitle = computed(() => {
  return Object.keys(suggestionsData.value)
})

/*const suggestionsData = computed(() => {
  return {
    destinations: [
      'Japan',
      'Vietnam',
      'Thailand island',
      'India',
      'Morocco',
      'Italy'
    ],
    durations: [
      '10 day unique culture of Japan',
      '10 day of Vietnam',
      '8 day imperial cities of Morocco'
    ],
    travel_themes: [
      'Thailand island hopping',
      'Family tour to India',
      'A week in Italy'
    ]
  }
})*/
const suggestionsData = computed(() => searchStore.suggestionsSearch.list)

const splitTitle = (name) => {
  return name.replaceAll('_', ' ')
}

const isLoading = computed(
  () => searchStore.getSuggestionSearchStatus,
)
</script>

<style lang="scss" scoped>
.suggested-box {
  max-width: 595px;

  @include phone{
    width: 100%;
    height: 120vh;
    max-width: unset;
  }
}

.trending {
  width: 310px;
  &-mobile {
    width: 100%;
  }
}

.trip-plan {
  max-height: 364px;
  overflow: hidden;
  //&-mobile {
  //  max-height: 184px;
  //  overflow: hidden;
  //}
}
</style>
