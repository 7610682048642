import { useFetchPublicApi } from '../fetch/fetchComposables';
import { contentServiceURL } from './apiDomain'
import { createHttp } from './http'

// let client = createHttp(contentServiceURL);

export default {
    getSuggestionSearch() {
        return useFetchPublicApi.get(`${contentServiceURL}/suggestion-define-search`, {}, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getSuggestionByKeyword(params) {
        const client = createHttp(contentServiceURL);
        return client.get('/suggestion-search', { params: params })
    },
    getSearchResult(params) {
        const client = createHttp(contentServiceURL);
        return client.get(`/search`, { params: params })
    },
    // getGenerativeSearch({ keyword, month, client_country = 'AU' }) {
    //     return client.get(`/generative-text`, {
    //         params: {
    //             q: keyword,
    //             month,
    //             client_country
    //         }
    //     })
    // },

    getStreamHttpSearch({ keyword, month = 'August', client_country = 'AU', user_id, tracking_id }) {
        return `/integration/v1/generative/generate-text?q=${keyword}&country=${client_country}&current_month=${month}&user_id=${user_id}&tracking_id=${tracking_id}`
    },
    getDesignersSearch(params) {
        const client = createHttp(contentServiceURL);
        return client.get(`/designers-search`, { params: params })
    },
    getTravelGuidesAndBlogs(params) {
        const client = createHttp(contentServiceURL);
        return client.get('/travel-guide-blogs-search', { params: params })
    }
}
